















































import Component, { mixins } from "vue-class-component";
import { EmitClose, LoadingMixin } from "@/mixins/Helpers";
import ProgramDetailsForm from "@/components/programs/DetailsForm.vue";
import ProgramPricingForm from "@/components/programs/PricingForm.vue";
import ProgramPlayers from "@/components/programs/ProgramPlayers.vue";
import ProgramRequest from "@/components/programs/ProgramRequest.vue";
import CopyProgram from "@/components/programs/CopyProgram.vue";
import { Program } from "@sportango/backend";
import AddIcon from "@/assets/icons/saxcons/add-broken.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import ProgramCard from "@/components/programs/ProgramCard.vue";
import { Watch } from "vue-property-decorator";
import { TimerMixin } from "@/mixins/TimerMixin";

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { WatchLoading } from "@/decorators/Loading";
import DeleteProgram from "@/components/programs/DeleteProgram.vue";

@Component({
  name: "view-or-edit-program",
  components: {
    DeleteProgram,
    LottieAnimation,
    ProgramDetailsForm,
    ProgramPricingForm,
    ProgramPlayers,
    AddIcon,
    ProgramCard,
    ProgramRequest,
    CopyProgram
  }
})
export default class ViewAndEditProgram extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  TimerMixin,
  EmitClose
) {
  private programLoaded = false;
  private snackbar = false;
  private contentHeight = "100vh";
  timers = [
    {
      name: "contentMixin",
      repeat: false,
      autostart: false,
      time: 250,
      callback: () => {
        this.updateContentHeight();
      }
    }
  ];
  private get program(): Program | undefined {
    return this.$store.getters.programs.find(
      (p) => p.id === this.$route.params.id
    );
  }

  get component(): string {
    switch (this.$route.query.mode) {
      case "edit":
        return "ProgramDetailsForm";
      case "pricing":
        return "ProgramPricingForm";
      case "players":
        return "ProgramPlayers";
      case "request":
        return "ProgramRequest";
      case "copy-program":
        return "CopyProgram";
      case "delete":
        return "DeleteProgram";
    }
    return "ProgramCard";
  }

  @WatchLoading()
  async mounted(): Promise<void> {
    await this.$store.dispatch("refreshProgram", this.$route.params.id);
    this.programLoaded = true;
  }

  @Watch("programLoaded")
  updateContentHeight(): void {
    const dialogHeight =
      document.querySelector(".dialog-container")?.clientHeight || 0;
    this.contentHeight = dialogHeight - 32 + "px";
  }
}
