


















































































import Component, { mixins } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import { Program, ProgramEntity } from "@sportango/backend";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CourtsMixin, LoadingMixin } from "@/mixins/Helpers";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import DialogTimePicker from "@/components/Inputs/DialogTimePicker.vue";
import { requiredFieldRule } from "@/utils/validation";
import { SportangoTextField } from "../Inputs/overrides";
import { WatchLoading } from "@/decorators/Loading";
import { ProrationMixin } from "@/mixins/Payments";

@Component({
  name: "copy-program",
  components: {
    DialogDatePicker,
    DialogTimePicker,
    SportangoTextField
  }
})
export default class CopyProgram extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  CourtsMixin,
  ProrationMixin
) {
  @Prop({ required: false })
  private existingProgram: Program | undefined;
  @Ref("programForm")
  private programFormRef!: HTMLFormElement;
  private copyPrice = true;
  private copyPlayers = true;
  private isValid = false;
  private program = new ProgramEntity({});
  private readonly requiredFieldRule = requiredFieldRule;
  private readonly endDateRules = [
    ...requiredFieldRule("End Date"),
    this.validateEndDate
  ];

  private validateEndDate(v: string) {
    if (this.program.startDate === undefined) {
      return "Please select Start Date First";
    }
    if (new Date(v) <= new Date(this.program.startDate)) {
      return "End Date must be after Start Date";
    }
    return true;
  }

  get title(): string {
    return this.existingProgram?.name || "";
  }
  @WatchLoading()
  private async submit(): Promise<void> {
    if (!this.copyPrice) {
      this.program.prices = undefined;
    }
    if (!this.copyPlayers) {
      this.program.programPlayers = undefined;
    }
    if (this.program.programPlayers) {
      this.program.programPlayers = this.program.programPlayers.map((p) => {
        if (p.price) {
          p.startDate = this.program.startDate;
          p.price = this.calculateProgramPlayerProratePrice(p, this.program);
        }
        return p;
      });
    }
    if (this.program.id) {
      this.program.id = undefined;
    }
    await this.$store.dispatch("addProgram", this.program);
    this.$emit("next");
    this.$emit("close");
  }

  async mounted(): Promise<void> {
    if (this.existingProgram) {
      this.program = new ProgramEntity({
        ...this.existingProgram,
        startDate: undefined,
        endDate: undefined,
        playerRequests: undefined
      });
      this.programFormRef.validate();
    }
  }
  @Watch("copyPrice")
  togglePlayers(v: boolean) {
    if (!v) {
      this.copyPlayers = false;
    }
  }
}
