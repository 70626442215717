























import { WatchLoading } from "@/decorators/Loading";
import { EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { Program } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "delete-program"
})
export default class DeleteProgram extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  EmitClose
) {
  @Prop({ required: false })
  existingProgram!: Program | undefined;

  @WatchLoading()
  async deleteProgram() {
    await this.$store.dispatch("deleteProgram", this.existingProgram?.id);
    this.close();
  }
}
