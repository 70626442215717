




















































import Component, { mixins } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import { Program, ProgramPlayerRequests } from "@sportango/backend";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CurrentUserMixin, EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { requiredFieldRule } from "@/utils/validation";
import { forFirestore } from "@/utils/parser";
import DialogDatePicker from "../Inputs/DialogDatePicker.vue";
import DaysInput from "../Inputs/DaysInput.vue";
import { IInfoWindow } from "@/store/types";

@Component({
  name: "program-request",
  components: {
    DaysInput,
    DialogDatePicker
  }
})
export default class ProgramRequest extends mixins(
  ResponsiveMixin,
  CurrentUserMixin,
  LoadingMixin,
  EmitClose
) {
  @Prop({ required: true })
  private existingProgram!: Program;
  isFormValid = false;
  player: ProgramPlayerRequests = {};
  requiredFieldRule = requiredFieldRule;

  @Ref("form")
  form!: HTMLFormElement & { validate: () => void };

  private checkIfDayAllowed(day: string): boolean | undefined {
    if (this.existingProgram.days) {
      if (!this.existingProgram.days.includes(day as Program.DaysEnum)) {
        return false;
      } else {
        // Calculate capacity and mark as empty
        if (
          this.existingProgram.programPlayers &&
          this.existingProgram.capacity
        ) {
          const capacityForDay = this.existingProgram.programPlayers.filter(
            (p) => p.days && p.days.includes(day as Program.DaysEnum)
          );
          return (
            this.existingProgram.capacity > capacityForDay.length ||
            this.player.days?.includes(day as Program.DaysEnum) ||
            this.existingProgram.programPlayers
              .find((p) => p.uid === this.player.uid)
              ?.days?.includes(day as Program.DaysEnum)
          );
        }
      }
    }
    return true;
  }

  async submit(): Promise<void> {
    this.isLoading = true;
    if (this.existingProgram.id) {
      const payload: Partial<Program> = {
        id: this.existingProgram.id,
        playerRequests: [
          ...(this.existingProgram.playerRequests || []).map((pr) => {
            return forFirestore(pr);
          }),
          forFirestore({
            uid: this.currentUser?.uid,
            startDate: this.player.startDate,
            days: this.player.days
          })
        ]
      };
      await this.$store.dispatch("updateProgram", payload);
    }

    if (
      this.currentUser?.permissions.hasPlayerAccess &&
      !this.currentUser?.permissions.hasCoachAccess
    ) {
      const infoWindow: IInfoWindow = {
        type: "SUCCESS",
        open: true,
        headingContent: "Requested!",
        actionText: "Ok,got it.",
        bodyContent: ` Your request for
        <strong>${this.existingProgram.name ?? "the program"}</strong>
        was sent.`
      };
      this.$store.commit("infoWindow", infoWindow);
    }
    this.isLoading = false;
    this.close();
  }

  mounted() {
    this.form.validate();
  }
}
